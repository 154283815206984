import React from "react";
import { TextInput } from "@narmi/design_system";

export function checkConstraints(image, file, constraints) {
  if (
    image.width > constraints.max_width ||
    image.height > constraints.max_height ||
    image.width < constraints.min_width ||
    image.height < constraints.min_height ||
    file.size > constraints.size
  ) {
    return false;
  }
  return true;
}

export const ImageUploadInput = ({
  defaultValue,
  onChange,
  error,
  attemptedValue,
  constraints,
  backgroundColor,
}) => {
  const [errorMsg, setErrorMsg] = React.useState(error);
  const [value, setValue] = React.useState(
    error && attemptedValue != null ? attemptedValue : defaultValue
  );

  const subChange = async (e) => {
    if (e != null) {
      const img = document.createElement("img");
      const file = e.target.files[0];
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (!checkConstraints(img, file, constraints)) {
          const sizeInMBs = constraints.max_content_length / 10 ** 6;
          setErrorMsg(
            `Image dimensions/size invalid. Valid dimensions ${constraints.min_width}-${constraints.max_width}x${constraints.min_height}-${constraints.max_height}. Valid file size: ${sizeInMBs} MBs`
          );
        } else {
          setErrorMsg("");
          setValue(img.src);
          onChange(img.src);
        }
      };
    }
  };
  return (
    <div>
      <input type="hidden" name="setting_value" id="setting_value" />
      <TextInput
        type="file"
        onChange={subChange}
        name="setting_image_preview"
        id="setting_image_preview"
        accept={constraints.allowed_types}
        error={errorMsg}
      />
      {value && <img src={value} alt="logo_img" id="image_value" style={{ backgroundColor }} />}
    </div>
  );
};

export default ImageUploadInput;
