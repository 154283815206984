import React, { useEffect, useState } from 'react'
import { Checkbox, CollapsibleCard, Button, Row } from '@narmi/design_system'
import styles from './styles/SyncSettingsContainer.module.css'


function SettingsList({ name, displayName, selected, setState, setCompare }) {
  return (
    <div style={{ borderBottom: '1px solid #e0e0e0' }}>
      <Row alignItems="center">
        <Row.Item>
          <Checkbox
            name={name}
            value={selected}
            kind="condensed"
            onChange={(evt) => {
              evt.stopPropagation();
              setState();
            }}
            checked={selected}
            markdownLabel={`*${displayName}*`}
          />
        </Row.Item>
        <Row.Item shrink>
          <div className='padding--y--s padding--left--s'>
            <Button label="Show diff" kind="plain" onClick={(evt) => {
              evt.stopPropagation();
              setCompare(name);
            }} />
          </div>
        </Row.Item>
      </Row>
    </div>)
}

function SelectableSettingsList({ currSettings, newSettings, selected, setSelected, setSettingToCompare }) {
  const [selectedIsOpen, setSelectedIsOpen] = useState(Object.keys(selected).length > 0)
  const [unSelectedIsOpen, setUnSelectedIsOpen] = useState(Object.keys(selected).length !== Object.keys(newSettings).length)
  const settingsLists = Object.entries(newSettings).reduce((acc, setting) => {
    const [name] = setting
    const [selectSettings, unSelectSettings] = acc
    if (name in selected) {
      selectSettings[name] = newSettings[name]
    }
    else {
      unSelectSettings[name] = newSettings[name]
    }
    return [selectSettings, unSelectSettings]
  }, [{}, {}])

  const setSettingToValue = (name, value) => {
    const copy = structuredClone(selected)
    if (value === null) {
      delete copy[name]
    } else {
      copy[name] = value
    }
    setSelected(copy)
  }

  useEffect(() => { }, [newSettings])
  return (<>
    {settingsLists.map((settingsList, idx) => {
      const keyName = idx === 0 ? 'selected' : 'unselected';
      return (
        <div className={styles.syncSettingsList} key={keyName}>
          <CollapsibleCard
            isDisabled={Object.keys(settingsList).length === 0}
            isOpen={idx === 0 ? selectedIsOpen : unSelectedIsOpen}
            onOpen={() => idx === 0 ? setSelectedIsOpen(true) : setUnSelectedIsOpen(true)}
            onClose={() => idx === 0 ? setSelectedIsOpen(false) : setUnSelectedIsOpen(false)}
            title={`${idx === 0 ? "Selected for update" : "Not selected"}`}
            subtitle={`(${Object.keys(settingsList).length} of ${Object.keys(newSettings).length})`}
            trigger="caret-end">

            {Object.entries(settingsList).map((setting) => {
              const [name, value] = setting
              return (<SettingsList key={`${name}-${keyName}`} name={name} displayName={currSettings[name].human_facing_name} selected={name in selected} setState={() => { setSettingToValue(name, idx === 0 ? null : value) }} setCompare={setSettingToCompare} />)
            })}
          </CollapsibleCard>
        </div>
      )
    })}
  </>
  )
}

export default SelectableSettingsList;
