import React from "react"
import { Button, Dialog } from '@narmi/design_system'
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

export default function SettingComparisonModal({ newValue, currSetting, isOpen, setSettingToCompare }) {
  const previewStyles = {
    variables: {
      light: {
        addedBackground: "#fff",
        removedBackground: "#fff",
        addedGutterBackground: "##fff",
        removedGutterBackground: "#f7f7f7",
        wordAddedBackground: "#37B374",
        wordRemovedBackground: "#D93B3B",
        emptyLineBackground: "#D93B3B",
      },
    },
  };
  let comparedValue = newValue;
  if (currSetting?.type?.toLowerCase().includes("json")) {
    try {
      comparedValue = JSON.stringify(JSON.parse(newValue), null, 4);
    } catch (error) {
      console.error("Error parsing JSON", error);  // eslint-disable-line no-console
    }
  }
  return (
    <Dialog
      footer={<div style={{ textAlign: 'right' }}><Button label="Close" kind="plain" onClick={() => setSettingToCompare(null)} /></div>}
      headerStyle="banner"
      onUserDismiss={() => setSettingToCompare(null)}
      title={`Comparing ${currSetting?.human_facing_name}`}
      width="1000px"
      isOpen={isOpen}
      key={currSetting.name}
      className="setting-comparison-modal"
    >
      <div className="padding--all--xs">
        <ReactDiffViewer
          oldValue={currSetting.value}
          newValue={comparedValue}
          splitView
          styles={previewStyles}
          compareMethod={DiffMethod.WORDS}
          leftTitle="Current Value"
          rightTitle="New Value"
        />
      </div >
    </Dialog >
  )
}
