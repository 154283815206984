import React from "react";
import { TextInput } from "@narmi/design_system";
import StateSelector from "../StateSelector";
import ZipCodeTextInput from "../ZipCodeTextInput";
import AutocompleteStreetAddress from "./AutocompleteStreetAddress";
import AddressAutoCompleteInput from "../AddressAutocompleteInput";

const AddressField = ({
  address_type,
  onUpdate,
  applicantIndex,
  data,
  errors = {},
  onChange,
  title,
  showTitle = true,
  streetAddressLabel = "Street address",
  apartmentLabel = "Apt/Fl/Suite",
  cityLabel = "City",
  stateLabel = "State",
  zipCodeLabel = "Zip code",
  marginTop = "margin--top--l",
  marginBottom = "margin--bottom--l",
  onBlur = () => null,
}) => {
  return (
    <>
      {address_type && showTitle && (
        <div
          className="margin--top--xl fontWeight--bold"
          style={{ color: "RGB(var(--nds-black))" }}
        >
          {title || address_type}
        </div>
      )}
      <div className={`business-info-form ${marginTop} ${marginBottom}`}>
        <div style={{ flex: 2, marginBottom: 0 }}>
          {window.google && window.google.maps.places ? (
            <AddressAutoCompleteInput
              value={data.street_address || ""}
              onChange={(street_address) => onChange({ street_address })}
              address_type={address_type}
              onUpdate={onUpdate}
              applicantIndex={applicantIndex}
              error={errors.street_address}
              streetAddressLabel={streetAddressLabel}
              onBlur={(event) => {
                onBlur(event, "street_address");
              }}
            />
          ) : (
            <TextInput
              value={data.street_address || ""}
              label="Street address"
              onChange={(event) => onChange({ street_address: event.target.value })}
              error={errors.street_address}
              onBlur={(event) => {
                onBlur(event, "street_address");
              }}
            />
          )}
        </div>
        <div style={{ flex: 1, marginBottom: 0 }}>
          <TextInput
            label={apartmentLabel}
            value={data.street_address_2}
            onChange={(event) => onChange({ street_address_2: event.target.value })}
            error={errors.street_address_2}
            onBlur={(event) => {
              onBlur(event, "street_address_2");
            }}
          />
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        <TextInput
          label={cityLabel}
          value={data.city}
          onChange={(event) => onChange({ city: event.target.value })}
          error={errors.city}
          onBlur={(event) => {
            onBlur(event, "city");
          }}
        />
      </div>
      <div className={`business-info-form ${marginTop} ${marginBottom}`}>
        <div style={{ flex: 1 }} className="sentry-mask">
          <StateSelector
            label={stateLabel}
            value={data.region_code}
            onChange={(region_code) => onChange({ region_code })}
            error={errors.region_code}
            onBlur={(event) => {
              onBlur(event, "region_code");
            }}
          />
        </div>
        <div style={{ flex: 2, marginBottom: 0 }}>
          <ZipCodeTextInput
            label={zipCodeLabel}
            field="postal_code"
            value={data.postal_code}
            onChange={(postal_code) => onChange({ postal_code })}
            error={errors.postal_code}
            onBlur={(event) => {
              onBlur(event, "postal_code");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddressField;
