import React from "react";
import ReactDOM from "react-dom/client";
import SettingsPageContainer from "./institution_settings/SettingsPageContainer";

function renderReactElements() {
  const CONTAINERS = {
    "#institution_settings_container": SettingsPageContainer,
  };

  Object.keys(CONTAINERS).forEach((selector) => {
    const containers = document.querySelectorAll(selector);
    containers.forEach((container) => {
      // make a react version of the previous container children
      const reactCopy = React.createElement("div", {
        dangerouslySetInnerHTML: { __html: container.innerHTML },
      });
      const root = ReactDOM.createRoot(container);
      root.render(
        React.createElement(
          CONTAINERS[selector],
          { ...container.dataset },
          reactCopy
        )
      );
    });
  });
};

document.addEventListener("DOMContentLoaded", renderReactElements);
