import React from "react";
import { Button, Dialog, Row } from "@narmi/design_system";


export default function SyncConfirmationModal({ currInstitutionName, importingInstitutionName, isConfirmModalOpen, setConfirmModalOpen, toSync = {}, onConfirm = () => { } }) {
  const numberOfSettings = Object.keys(toSync).length

  return (
    <>
      <Dialog
        isOpen={isConfirmModalOpen}
        headerStyle="banner"
        width="1000px"
        footer={
          <Row justifyContent="end" alignItems="center">
            <Row.Item shrink>
              <Button kind="plain" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
            </Row.Item>
            <Row.Item shrink>
              <Button onClick={() => {
                setConfirmModalOpen(false)
                onConfirm()
              }}
              >Submit</Button>
            </Row.Item>
          </Row>
        }
        onUserDismiss={() => setConfirmModalOpen(false)}
        title={`I confirm that I want to upload this configuration file from ${importingInstitutionName} and overwrite ${numberOfSettings} institution setting${numberOfSettings > 1 ? "s" : ""} and ALL translations for ${currInstitutionName}.`}
      >
        <ul>
          {Object.keys(toSync).map((settingName) => (<li key={`submit-${settingName}`}>{settingName}</li>))}
        </ul>
      </Dialog >
    </>
  )
};
