import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { jsonFromDocument, LoadingContextProvider, NotificationContextProvider } from "cerulean"; // eslint-disable-line import/no-unresolved
import SettingsPageRouter, {
  flattenGroupSettings,
  groupPath,
  settingPath,
  useRouterPathParams,
} from "./SettingsPageRouter";
import SyncSettingsContainer from "./sync/SyncSettingsContainer";

const getBaseUrl = (pageName = "institution_settings") => {
  const path = window.location.pathname;
  const i = path.indexOf(pageName);
  return i === -1 ? path : path.substring(0, i + pageName.length);
};

const SettingsPage = ({ settings, initialState }) => {
  const defaultGroup = initialState.group || Object.keys(settings)[0];
  const defaultSubgroup = initialState.subgroup || Object.keys(settings[defaultGroup])[0];
  const attemptedValue = initialState.attempted_value;

  const [error, setError] = useState(initialState.error || null);
  const [errorWasShown, setErrorWasShown] = useState(!error);

  const { group, subgroup, settingName } = useRouterPathParams();
  const history = useHistory();

  const qp = new URLSearchParams(useLocation().search);

  useEffect(() => {
    /*
      An initialState.setting is only set when an error is returned.

      On first mount, either:
        1. If routing to "sync", navigate to the sync settings page
        2. If using the opening product wizard, navigate to the appropriate page
        3. If there was an error, navigate to the offending setting page.
        4. If the route is '/', navigate to the first group & subgroup.
        5. If the group and subgroup route is user-entered, reformat and prettify
        6. If setting name is user-entered, reformat and prettify
           If Opening Product Wizard, only group is set
           and hackily does not fall into any of the if statements.
           Will route using OpeningProductWizard container.
    */
    if (initialState.opw?.operation) {
      if (initialState.opw.id) {
        history.push(`/products/${initialState.opw.id}/${initialState.opw.operation}`);
      } else {
        history.push("/products");
      }
    } else if (error && initialState.setting) {
      if (initialState.setting.name !== settingName) {
        history.push(settingPath(initialState.setting));
      }
      setErrorWasShown(true);
    } else if (!group && !subgroup && !settingName) {
      history.push(`${groupPath(defaultGroup, defaultSubgroup)}?${qp.toString()}`);
    } else if (group && subgroup) {
      history.push(`${groupPath(group, subgroup)}?${qp.toString()}`);
    } else if (settingName) {
      history.push(`${settingPath({ name: settingName })}?${qp.toString()}`);
    }
  }, []);

  useEffect(() => {
    // Remove the error if it is corrected or navigated away from.
    if (error && errorWasShown) {
      setError(null);
    }
  }, [group, settingName]);

  const isSyncSettings = settingName === "SYNC" && jsonFromDocument("has_import_export_settings", "false") === true;

  if (isSyncSettings) {
    return (
      <NotificationContextProvider>
        <LoadingContextProvider>
          <div className="ui column grid bgColor--snowGrey" id="sync-settings-container">
            <SyncSettingsContainer settings={flattenGroupSettings(settings)} />
          </div>
        </LoadingContextProvider>
      </NotificationContextProvider>
    )
  }
  return (<div className="ui column grid bgColor--snowGrey" id="setting_page_container">
    <SettingsPageRouter settings={settings} error={error} attemptedValue={attemptedValue} />
  </div>)
};

const SettingsPageContainer = () => {
  const settings = jsonFromDocument("institution_settings");
  const initialState = jsonFromDocument("initial_state");
  Object.keys(settings).forEach((group) => {
    Object.keys(settings[group]).forEach((subgroup) => {
      Object.keys(settings[group][subgroup]).forEach((setting) => {
        if (
          ["JsonDictSettingType", "JsonArraySettingType", "JsonStringSettingType"].includes(
            settings[group][subgroup][setting]?.type
          )
        ) {
          const currSetting = settings[group][subgroup][setting];
          currSetting.value = JSON.stringify(JSON.parse(currSetting.value), null, 4);
        }
      });
    });
  });
  return (
    <div>
      <Router basename={getBaseUrl()}>
        <CompatRouter>
          <SettingsPage settings={settings} initialState={initialState} />
        </CompatRouter>
      </Router>
    </div>
  );
};

export default SettingsPageContainer;
