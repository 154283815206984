import React, { useState, useEffect } from "react";
import { matchPath, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { NotificationContextProvider } from "cerulean"; // eslint-disable-line import/no-unresolved
import SettingsMenu from "./SettingsMenu";
import SettingsList from "./SettingsList";
import EditScreen from "./EditScreen";
import AOAskAdditionalField from "./AOAskAdditionalField";

const PATHS = {
  GROUP: "/:group/:subgroup",
  SETTING: "/:settingName",
  OPW: "/:product(products|product)/:uuid/:operation(edit|deactivate|delete)",
};
const prettifySettingRoute = (value) => value.toLowerCase();
const prettifyGroupRoute = (value) => prettifySettingRoute(value).replace(/ /g, "_");
export const groupPath = (group, subgroup) =>
  `/${prettifyGroupRoute(group)}/${prettifyGroupRoute(subgroup)}`;
export const settingPath = ({ name }) => `/${prettifySettingRoute(name)}`;
const groupPathForSetting = ({ grouping }) => groupPath(grouping[0], grouping[1]);

export const useRouterPathParams = (getLocation = useLocation) => {
  const { pathname } = getLocation();
  let params = {
    group: null,
    subgroup: null,
    settingName: null,
    product: null,
    uuid: null,
    operation: null,
  };
  Object.values(PATHS).forEach((route) => {
    let formattedPathname = pathname.toUpperCase();
    if (route === PATHS.GROUP) {
      formattedPathname = formattedPathname.replace(/_/g, " ");
    }
    const match = matchPath(formattedPathname, { path: route, exact: true });
    if (match) {
      params = { ...params, ...match.params };
    }
  });
  if (params.group === null && params.subgroup === null && params.settingName === null) {
    if (params.product === "PRODUCT" || params.product === "PRODUCTS") {
      params.group = "PRODUCTS";
    } else {
      params.group = "GENERAL";
      params.subgroup = "INSTITUTION INFORMATION";
    }
  }
  return params;
};

const RoutedSettingsMenu = ({ settings, forceClose = false }) => {
  const { group, subgroup } = useRouterPathParams();
  return (
    <SettingsMenu
      settings={settings}
      active={{ group, subgroup }}
      getGroupPath={groupPath}
      forceClose={forceClose}
    />
  );
};

export const flattenGroupSettings = (settings) => {
  let flatSettings = [];
  Object.keys(settings).forEach((group) => {
    Object.keys(settings[group]).forEach((subgroup) => {
      flatSettings = flatSettings.concat(settings[group][subgroup]);
    });
  });
  return flatSettings;
};

const RoutedSettingsList = ({ settings, group, subgroup, searchTerm }) => (
  <SettingsList
    settingsList={settings}
    group={group}
    subgroup={subgroup}
    getSettingPath={settingPath}
    searchTerm={searchTerm}
  />
);

export const settingByName = (settings, name) => {
  const match = [];
  Object.keys(settings).forEach((group) =>
    Object.keys(settings[group]).forEach((subgroup) =>
      settings[group][subgroup].forEach((setting) => {
        if (setting.name === name) {
          match.push(setting);
        }
      })
    )
  );
  return match[0] || null;
};

const RoutedEditScreen = ({ settings, error, attemptedValue }) => {
  const { settingName } = useRouterPathParams();
  const setting = settingByName(settings, settingName);
  const backPath = groupPathForSetting(setting);

  if (setting.name === "OAO_ASK_ADDITIONAL_FIELDS") {
    return (
      <AOAskAdditionalField
        setting={setting}
        attemptedValue={attemptedValue}
        backPath={backPath}
        error={error}
      />
    );
  }

  return (
    <EditScreen
      setting={setting}
      attemptedValue={attemptedValue}
      backPath={backPath}
      error={error}
    />
  );
};

const SettingsPageRouter = ({ settings, error, attemptedValue }) => {
  const history = useHistory();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);
  const search = qp.get("search");

  const [currentSubgroup, setCurrentSubgroup] = useState(null);
  const [currentSettings, setCurrentSettings] = useState(search ? [] : null);
  const [searchTerm, setSearchTerm] = useState(search || "");
  const { group, subgroup } = useRouterPathParams();
  const subgroupSettings = subgroup ? settings[group][subgroup] : [];
  const allSettings = flattenGroupSettings(settings);

  useEffect(() => {
    if (currentSubgroup !== subgroup && !searchTerm) {
      setCurrentSubgroup(subgroup);
      setCurrentSettings(null);
      setSearchTerm("");
    }
  }, [subgroup]);

  const searchForResults = (value) => {
    setSearchTerm(value);
    const seenSettings = {};
    const nameMatches = [];
    const displayValueMatches = [];
    const helpTextMatches = [];
    allSettings.forEach((setting) => {
      const lowerTerm = value.toLowerCase();
      if (seenSettings[setting.name] === undefined) {
        seenSettings[setting.name] = true;
        if (
          setting.human_facing_name?.toLowerCase().includes(lowerTerm) ||
          setting.name.toLowerCase().includes(lowerTerm)
        ) {
          nameMatches.push(setting);
        } else if (setting.display_value?.toString().toLowerCase().includes(lowerTerm)) {
          displayValueMatches.push(setting);
        } else if (setting.help_text?.toLowerCase().includes(lowerTerm)) {
          helpTextMatches.push(setting);
        }
      }
    });
    const searchResults = [...nameMatches, ...displayValueMatches, ...helpTextMatches];
    if (value.length === 0) {
      setCurrentSettings([]);
    } else if (value.length > 1 && searchResults.length === 0) {
      setCurrentSettings([]);
    } else {
      setCurrentSettings(searchResults);
    }
    if (value !== qp.get("search")) {
      if (value) {
        qp.set("search", value);
      } else {
        qp.delete("search");
      }
      history.replace({ pathname: location.pathname, search: qp.toString() });
    }
  };
  const handleBlur = (event) => {
    if (event.target.value.length === 0) {
      setCurrentSettings(null);
    }
  };
  const cancelButton = (
    <button
      className="nds-plain-button"
      type="button"
      href="#"
      onClick={() => {
        qp.delete("search");
        setSearchTerm("");
        setCurrentSettings(null);
        history.replace({ pathname: location.pathname, search: qp.toString() });
      }}
      style={{
        color: "rgba(var(--nds-medium-grey))",
        paddingLeft: 8,
        border: "none",
        backgroundColor: "transparent",
        fontWeight: "normal",
      }}
    >
      Cancel
    </button>
  );

  useEffect(() => {
    if (search) {
      searchForResults(search);
    }
  }, []);

  return (
    <Switch>
      <Route exact path={PATHS.GROUP}>
        <RoutedSettingsMenu settings={settings} forceClose={currentSettings !== null} />
        <div className="column twelve wide">
          <div
            style={{
              width: currentSettings === null ? 400 : "100%",
              float: "right",
              paddingLeft: "var(--space-s)",
              paddingRight: "var(--space-s)",
            }}
          >
            <div className="search-bar">
              <div className="ui icon input">
                <i className="narmi-icon-search message-search-icon fontColor--secondary" />
                <input
                  type="text"
                  className="field"
                  value={searchTerm}
                  onChange={(e) => searchForResults(e.target.value)}
                  onFocus={(e) => searchForResults(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Search"
                />
              </div>
              {currentSettings !== null ? cancelButton : null}
            </div>
          </div>
          <RoutedSettingsList
            settings={currentSettings === null ? subgroupSettings : currentSettings}
            group={group}
            subgroup={searchTerm === "" ? subgroup : ""}
            searchTerm={searchTerm}
          />
        </div>
      </Route>
      <Route exact path={PATHS.SETTING}>
        <NotificationContextProvider>
          <RoutedEditScreen settings={settings} error={error} attemptedValue={attemptedValue} />
        </NotificationContextProvider>
      </Route>
    </Switch>
  );
};

export default SettingsPageRouter;
