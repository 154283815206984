import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@narmi/design_system";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { CsrfTokenMiddleware, Tabs, Toggle } from "cerulean"; // eslint-disable-line import/no-unresolved
import HistoryTable from "./HistoryTable";
import useUserConfiguration from "./hooks/useUserConfiguration";

const CONSUMER_FIELDS = [
  {
    name: "Occupation",
    description:
      "Primary and joint applicants will be asked to add their occupation on the personal information section of the application.",
    category: "consumer",
  },
  {
    name: "E-sign checkbox",
    description:
      "Applicants will be asked to agree to e-sign terms and conditions on the ‘your info’ section of the application.",
    category: "consumer",
  },
  {
    name: "CAPTCHA",
    description:
      "Applicants will be asked to complete CAPTCHA on the personal information section of the application. Needs the Captcha Public Key setting to be set in order to work. WARNING: Domain must be added to Google reCAPTCHA allowed domains (https://www.google.com/recaptcha/admin ﹥Settings ﹥Domains)",
    category: "consumer",
  },
  {
    name: "Beneficiaries address",
    description:
      "Ask for address in the beneficiaries form on CAO. This field will be saved in Narmi and passed to the beneficiary profile in the core.",
    category: "consumer",
  },
  {
    name: "Beneficiaries relationship",
    description:
      "Ask for beneficiary relationship in the beneficiaries form on CAO. Relationship codes must be defined in the Beneficiaries Relationship Codes institution setting.",
    category: "consumer",
  },
  {
    name: "Beneficiaries SSN",
    description:
      "Ask for SSN in the beneficiaries form on CAO. This field will be saved in Narmi and passed to the beneficiary profile in the core.",
    category: "consumer",
  },
  {
    name: "Beneficiaries phone number",
    description:
      "Ask for phone number in the beneficiaries form on CAO. This field will be saved in Narmi and passed to the beneficiary profile in the core.",
    category: "consumer",
  },
  {
    name: "Beneficiaries email",
    description:
      "Ask for email in the beneficiaries form on CAO. This field will be saved in Narmi and passed to the beneficiary profile in the core.",
    category: "consumer",
  },
];
const BUSINESS_FIELDS = [
  {
    name: "Role",
    description:
      "Applicants will be asked to add their role on the ownership section of the application.",
    category: "business",
  },
  {
    name: "Website",
    description:
      "Applicants will be asked to add their website on the ‘your business’ section of the application.",
    category: "business",
  },
  {
    name: "CAPTCHA",
    description:
      "Applicants will be asked to complete CAPTCHA on the welcome screen of the application. Needs the Captcha Public Key setting to be set in order to work. WARNING: Domain must be added to Google reCAPTCHA allowed domains (https://www.google.com/recaptcha/admin ﹥Settings ﹥ Domains)",
    category: "business",
  },
  {
    name: "Non-DBA Business Name",
    description: "Ask for business name for sole proprietorships if the user selects 'no' for DBA.",
    category: "business",
  },
  {
    name: "Short date of incorporation",
    description: "Request date of incorporations in MM/YYYY format",
    category: "business",
  },
  {
    name: "Remove Electronic Funds Transfer agreement text",
    description: "Remove EFT text from 'Link your bank account' modal ",
    category: "business",
  },
  {
    name: "Assumed Business Name",
    description: "Ask 'Assumed Business Name' field for LLCs/Corporations",
    category: "business",
  },
];
const HIDE_FOR_NON_NARMI = ["E-sign checkbox"];

const previewStyles = {
  variables: {
    light: {
      addedBackground: "#fff",
      removedBackground: "#fff",
      addedGutterBackground: "##fff",
      removedGutterBackground: "#f7f7f7",
      wordAddedBackground: "#37B374",
      wordRemovedBackground: "#D93B3B",
      emptyLineBackground: "#D93B3B",
    },
  },
};

const AOAskAdditionalField = ({ setting, error, backPath }) => {
  const [settingArray, setSettingArray] = useState(JSON.parse(setting.value));
  const [noteValue, setNoteValue] = useState("Default note");
  const [hasChanged, setHasChanged] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = useState(urlParams.get("tab") || 0);
  const [oldPreviewValue, setOldPreviewValue] = React.useState("");
  const [newPreviewValue, setNewPreviewValue] = React.useState("");
  const [previewIsOpen, setPreviewIsOpen] = React.useState(false);

  const { currentUser } = useUserConfiguration();

  const submitForm = () => {
    document.getElementById(`${setting.name}_submit`).submit();
  };

  const getValueForFieldName = (category, fieldName) =>
    settingArray.find((s) => s.category === category && s.name === fieldName)?.value;

  const updateValue = (fieldName, category, value) => {
    // Add selected tab as urlparam to make sure the tab stays selected after form is submittted
    if (!urlParams.get("tab")) {
      urlParams.set("tab", selectedTab);
      window.history.replaceState(
        {},
        "",
        `/institution_settings/oao_ask_additional_fields?${urlParams.toString()}`
      );
    }
    const oldSettingIdx = settingArray.findIndex(
      (s) => s.category === category && s.name === fieldName
    );

    const newSettingArray = [...settingArray];
    let newSetting;
    if (oldSettingIdx < 0) {
      newSetting = {
        name: fieldName,
        value,
        category,
      };
      newSettingArray.push(newSetting);
    } else {
      newSetting = { ...settingArray[oldSettingIdx] };
      newSetting.value = value;
      newSettingArray[oldSettingIdx] = newSetting;
    }

    setSettingArray(newSettingArray);
    // Updates to a setting require a note, so create an automatic one
    setNoteValue(`[AUTOMATED] ${currentUser.email} updated ${fieldName} (${category}) to ${value}`);
    setHasChanged(true);
  };

  const setOldAndNewPreviewValues = (oldValue, newValue) => {
    setOldPreviewValue(oldValue);
    setNewPreviewValue(newValue);
    setPreviewIsOpen(true);
  };

  // Only submit the form if there has been an update to the toggle. The page refreshes
  // after the form submission, so this should still work even after repeated changes
  useEffect(() => {
    if (hasChanged) {
      submitForm();
    }
  }, [hasChanged]);

  const AdditionalFieldToggle = ({ field, value }) => (
    <div
      data-testid="additional-field-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="margin--bottom--xxs fontWeight--semibold">{field.name}</div>
          <div
            className="margin--bottom--s"
            style={{
              color: "var(--color-mediumGrey)",
            }}
          >
            {field.description}
          </div>
        </div>
        <div>
          <Toggle
            labelledBy={`${field.name}-${field.category}`}
            onChange={(next) => {
              updateValue(field.name.toLowerCase(), field.category, next);
            }}
            isActive={value}
          />
        </div>
      </div>
      <hr className="margin--top--m" style={{ marginLeft: 0, marginRight: 0 }} />
    </div>
  );

  return (
    <div className="column sixteen wide nds-typography">
      <form id={`${setting.name}_submit`} method="POST" className={error || ""}>
        <CsrfTokenMiddleware />
        <input type="hidden" name="setting_name" value={setting.name} />
        <input
          type="hidden"
          name="setting_value"
          id="setting_value"
          value={JSON.stringify(settingArray)}
        />
        <input type="hidden" name="note_value" id="note_value" value={noteValue} />
      </form>
      <div className="margin--bottom--m" style={{ display: "flex", alignItems: "center" }}>
        <Link style={{ marginTop: "14px" }} to={backPath}>
          <span
            style={{ fontSize: "20px", cursor: "pointer", color: "#333333" }}
            className="narmi-icon-chevron-left"
          />
        </Link>
        <h2 style={{ marginTop: "0.5rem" }}>Add a field to applications</h2>
      </div>
      <div className="padding--all--m">
        <Tabs
          selectedIndex={selectedTab}
          onTabChange={(index) => {
            setSelectedTab(index);
            urlParams.set("tab", index);
            window.history.pushState(
              {},
              "",
              `/institution_settings/oao_ask_additional_fields?${urlParams.toString()}`
            );
          }}
        >
          <Tabs.List>
            <Tabs.Tab key="consumer" label="Consumer Account Opening" tabId="consumer" />
            <Tabs.Tab key="business" label="Business Account Opening" tabId="business" />
            <Tabs.Tab key="change-log" label="Change log" tabId="change-log" />
          </Tabs.List>
          <Tabs.Panel tabId="consumer">
            <div className="additional-fields-container margin--top--m">
              {CONSUMER_FIELDS.filter(
                // Hide certain toggles for non-Narmi users
                (f) => !HIDE_FOR_NON_NARMI.includes(f.name) || currentUser.is_narmi
              )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((field) => (
                  <AdditionalFieldToggle
                    key={`${field.name.toLowerCase()}_consumer`}
                    field={field}
                    value={getValueForFieldName("consumer", field.name.toLowerCase())}
                  />
                ))}
            </div>
          </Tabs.Panel>
          <Tabs.Panel tabId="business">
            <div className="additional-fields-container margin--top--m">
              {BUSINESS_FIELDS.sort((a, b) => a.name.localeCompare(b.name)).map((field) => (
                <AdditionalFieldToggle
                  key={`${field.name.toLowerCase()}_business`}
                  field={field}
                  value={getValueForFieldName("business", field.name.toLowerCase())}
                />
              ))}
            </div>
          </Tabs.Panel>
          <Tabs.Panel tabId="change-log">
            <HistoryTable
              settingName={setting.name}
              setPreviewIsOpen={setPreviewIsOpen}
              setOldAndNewPreviewValues={setOldAndNewPreviewValues}
            />
            <Dialog
              isOpen={previewIsOpen}
              title="Preview"
              onUserDismiss={() => setPreviewIsOpen(false)}
              width="100%"
            >
              <div>
                <ReactDiffViewer
                  oldValue={oldPreviewValue}
                  newValue={newPreviewValue}
                  splitView
                  styles={previewStyles}
                  compareMethod={DiffMethod.WORDS}
                  leftTitle="Current Value"
                  rightTitle="New Value"
                />
              </div>
            </Dialog>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default AOAskAdditionalField;
