import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";

import { Alert, Button, Row, SeparatorList, TextInput, Snackbar } from '@narmi/design_system'

import { jsonFromDocument, useLoadingContext, useNotificationContext } from "cerulean";
import ReactMarkdown from "react-markdown";
import SelectableSettingsList from "./SyncSettingsList";
import SettingComparisonModal from "./SettingComparisonModal";
import SyncConfirmationModal from "./SyncConfirmationModal";
import styles from "./styles/SyncSettingsContainer.module.css";


function settingsListToDict(settings, returnFullSetting = false, conditional = () => false) {
  return settings.reduce((acc, setting) => {
    const { name } = setting
    if (conditional(name, setting.value)) {
      return acc
    }
    if (returnFullSetting) {
      acc[name] = setting
    } else {
      acc[name] = setting.value
    }
    return acc
  }, {})
}


function ExportSettingsContainer({ onExport = () => { } }) {
  const { isLoading } = useLoadingContext();
  return (
    <div className="">
      <div className={`${styles.alertContainer} padding--bottom`}>
        <Alert
          isActive
          kind="info"
          icon="alert-circle"
          alignItems="center"
          isDismissable={false}
        >
          <span>Note: Configuration files downloaded from this page are signed with a checksum.
            If you manually edit a file, it will no longer be able to be applied to an environment.</span>
        </Alert>
      </div>
      <Row>
        <Row.Item shrink>
          <Button
            kind="primary"
            label="Export current settings"
            onClick={() => onExport()}
            isLoading={isLoading}
          />
        </Row.Item>
      </Row>
    </div>
  )
}


async function computeChecksum(data) {
  // function should be functionally similar to support.views.narmi_admin.ConfigurationData.calculate_checksum
  // only use data if object
  if (typeof data !== 'object') {
    return null;
  }
  // only use specific keys from object
  const relevantKeys = ['institution_settings', 'translations', 'translations_manifest']; // specify the keys you want to use
  const filteredData = Object.keys(data)
    .filter(key => relevantKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];  // eslint-disable-line no-param-reassign
      return obj;
    }, {});
  // check all keys in data
  if (!Object.keys(filteredData).every(key => relevantKeys.includes(key))) {
    return null;
  }
  // Convert the filteredData to a JSON string and encode as UTF-8
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(JSON.stringify(filteredData));

  // Compute the SHA-256 digest
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);

  // Convert the ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}


function SyncSettingsContainer({ settings }) {
  const alertSplitStr = ",,";
  const currSettings = settingsListToDict(settings, true)
  const isSettingsSyncFeature = jsonFromDocument("has_import_export_settings", "false") === true;
  const currentReleaseVersion = jsonFromDocument("release_version");
  // configuration data should look like https://github.com/narmi/banking/blob/2c3d5f31c0f5b91f4fc11db0b5cddebef16a2867/support/views/narmi_admin.py#L89-L95
  const [newConfiguration, setNewConfiguration] = useState({})  // the unchanging configuration from uploaded file
  const [settingsToOverwrite, setSettingsToOverwrite] = useState({})  // the settings in the file that will be updated
  const [importedSettings, setImportedSettings] = useState({})  // the unchanging settings dict from uploaded file that are importable
  const [settingToCompare, setSettingToCompare] = useState(null)
  const [action, setAction] = useState(null)
  const [importingInstitutionName, setImportingInstitutionName] = useState("");
  const csrfToken = jsonFromDocument("csrf_token", "") || "CSRF-TOKEN-NOT-FOUND";
  const isNarmi = jsonFromDocument("current_user")?.is_narmi;
  const currInternalName = settings?.INSTITUTION_SHORT_NAME?.replace(" ", "_") ?? jsonFromDocument("institution_internal_name", "unknown");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const { setIsLoading } = useLoadingContext();
  const { sendInfoNotification, sendNegativeNotification, sendSuccessNotification } = useNotificationContext();

  function ReleaseVersionAlert() {
    const appendedAlertStr = "Please confirm this is what you intend to import."
    let alertStr = "";
    let alertType = "error";
    let versionAlertMarkdown = "";
    // match vXXXX.XX.XXX<possible-test-tag>
    const matchCurrent = String(currentReleaseVersion).match(/v\d{4}\.\d{1,2}\.\d{1,3}.*/);
    const matchNew = newConfiguration?.environment_version?.match(/v\d{4}\.\d{1,2}\.\d{1,3}.*/);
    const currentVersion = matchCurrent ? matchCurrent[0] : currentReleaseVersion;
    const newVersion = matchNew ? matchNew[0] : newConfiguration?.environment_version;
    if (currentVersion === newVersion) {
      alertStr = `The current version and importing version match. **${currentReleaseVersion}**`;
      alertType = "success";
    } else if (!matchCurrent || !matchNew) {
      alertStr = `The current version and importing version do not match. ${appendedAlertStr}`;
      versionAlertMarkdown = `- **${currentVersion}** current version\n\n- **${newVersion || "unknown"}** importing version`;
      alertType = "error";
    } else {
      let [currentYear, currentMonth] = currentVersion.split("v")[1].split(".", 3);
      let [newYear, newMonth] = newVersion.split("v")[1].split(".", 3);
      currentYear = parseInt(currentYear, 10);
      newYear = parseInt(newYear, 10);
      currentMonth = parseInt(currentMonth, 10);
      newMonth = parseInt(newMonth, 10);
      const yearDiff = Math.abs(currentYear - newYear);
      const monthDiff = Math.abs(yearDiff * 12 - Math.abs(currentMonth - newMonth));

      versionAlertMarkdown = `- **${currentVersion}** current version\n\n- **${newVersion}** importing version`;
      if (monthDiff > 0) {
        alertStr = `The current version and importing version differ by **${monthDiff > 1 ? "months" : "one month"}**. ${appendedAlertStr}`;
        alertType = monthDiff > 1 ? "error" : "warn";
      } else {
        alertStr = `The current version and importing version have different patch versions. ${appendedAlertStr}`;
        alertType = "info";
      }
    }
    return (
      <Alert
        isActive
        kind={alertType}
        icon="alert-triangle"
        isDismissable={false}
      >
        <span>
          <ReactMarkdown>{alertStr}</ReactMarkdown>
          {versionAlertMarkdown && <ReactMarkdown>{versionAlertMarkdown}</ReactMarkdown>}
        </span>
      </Alert>
    )
  }

  function clearData() {
    setImportingInstitutionName("");
    setSettingsToOverwrite({});
    setImportedSettings({});
    setSettingToCompare(null);
    setNewConfiguration({});
  }

  async function downloadSettings() {
    setIsLoading(true)
    const response = await fetch("/admin/export-settings/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    })
    if (!response.ok) {
      sendNegativeNotification("Error downloading settings.");
      setIsLoading(false)
      clearData();
      return
    }
    try {
      const data = await response.json();
      const blob = new Blob([JSON.stringify(data, null, 1)], { type: "application/json" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const date = new Date();
      a.setAttribute("download", `${data.institution_name}-${currentReleaseVersion}-${date.toLocaleDateString()}_${date.toLocaleTimeString()}-settings.json`);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      sendSuccessNotification("Settings downloaded successfully.");
    } catch (err) {
      sendNegativeNotification(`Error downloading settings. ${err}`);
    }
    clearData();
    setIsLoading(false);
  }

  async function loadSettings(file) {
    if (!file) {
      sendNegativeNotification("No file selected.");
      clearData();
      return
    }
    setIsLoading(true)
    const reader = new FileReader()
    let data = null;
    reader.onload = async function (e) {  // eslint-disable-line func-names
      try {
        data = JSON.parse(e.target.result);
        if (data?.institution_settings?.length === 0) {
          sendNegativeNotification("No settings found in file.");
          return
        }
        const newImportedSettings = settingsListToDict(
          data.institution_settings,
          false,
          (name, value) => (!(name in currSettings)) || currSettings[name].display_value === value || currSettings[name].value === value
        );
        if (Object.keys(newImportedSettings).length === 0) {
          sendInfoNotification("No settings to update.");
        } else {
          setNewConfiguration({ ...data, filename: file.name });
          // default to all selected
          setSettingsToOverwrite(newImportedSettings);
          setImportedSettings(newImportedSettings);
          setImportingInstitutionName(data?.institution_name ?? "Unknown institution");
          sendSuccessNotification("File uploaded successfully.");
          return;
        }
      } catch (err) {
        sendNegativeNotification(`Error loading settings. ${err}`);
      }
      clearData();
      setImportingInstitutionName(data?.institution_name || "");
    }
    reader.readAsText(file);
    setImportingInstitutionName("");
    setIsLoading(false);
  }

  async function submit() {
    setIsLoading(true);
    const configData = {
      ...newConfiguration,
      institution_settings: Object.entries(settingsToOverwrite).map(
        (setting) => ({
          name: setting[0],
          value: setting[1],
        })
      ),
      // should be unchanged
      translations: newConfiguration.translations || [],
      translations_manifest: newConfiguration.translations_manifest || [],
      strict_checksum: true,
    };
    const checksum = await computeChecksum(configData)
    if (checksum === null) {
      sendNegativeNotification("Error updating settings. Checksum could not be computed. Please contact support.");
      setIsLoading(false)
      return
    };
    configData.checksum = checksum;
    // check all keys are present from support.views.narmi_admin.ConfigurationData
    if (!Object.keys(configData).every(key => ['institution_settings', 'translations', 'translations_manifest', 'checksum', 'institution_name', 'filename', 'strict_checksum', 'environment_version'].includes(key))) {
      sendNegativeNotification("Error updating settings. Missing required keys. Please contact support.");
      setIsLoading(false)
      return
    };
    const response = await fetch("/admin/import-settings/", {
      method: "POST",
      body: JSON.stringify(configData, null, 1),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    });
    try {
      const data = await response.json();
      const urlParams = new URLSearchParams();
      if (!response.ok) {
        sendNegativeNotification(`Error updating settings. ${data.error}`);
      } else {
        if (data.warnings) {
          urlParams.append("warnings", data.warnings.join(alertSplitStr));
        }
        urlParams.append("success", data.success);
        window.location.href = `sync/?${urlParams.toString()}`;
      }
    } catch (err) {
      sendNegativeNotification(`Error updating settings. ${err}`);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (!isNarmi || !isSettingsSyncFeature) {
      // window redirect to homepage
      sendNegativeNotification("You do not have permission to access this page.");
      window.location.href = "/";
    }
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const success = urlParams.get("success");
      const warnings = urlParams.get("warnings");
      if (success) {
        sendSuccessNotification(success);
      }
      if (warnings) {
        warnings.split(alertSplitStr).forEach(warning => sendInfoNotification(warning));
      }
    }
  }, [])
  return (
    <div style={{ width: "100%" }}>
      <div className="padding--bottom">
        <Row alignItems="center">
          <Row.Item shrink>
            {action === null ? <Link
              to="general/institution_information"
            >
              <Button
                kind="plain"
                label=""
                startIcon="chevron-left"
                type="button"
              />
            </Link> :
              <Button
                kind="plain"
                label=""
                startIcon="chevron-left"
                type="button"
                onClick={() => { setAction(null); clearData(); }}
              />}
          </Row.Item>
          <Row.Item>
            <h1>Sync settings{action && ` | ${String(action).slice(0, 1).toUpperCase() + action.slice(1).toLowerCase()}`}</h1>
            {/* <i>Note: Configurations in the uploaded file are applied immediately. Download a copy of the current environment configuration before upload for rollback in case of error.</i> */}
          </Row.Item>
        </Row>
      </div>
      {action === null && <div className="actionSelectorContainer">
        <Row>
          <SeparatorList
            items={[
              <Button kind="plain" onClick={(e) => {
                e.stopPropagation();
                setAction("export");
              }}>Export current configuration</Button>,
              <Button kind="plain" onClick={(e) => {
                e.stopPropagation();
                setAction("import");
              }}>Import new configuration</Button>
            ]}
          />
        </Row>
      </div>}
      {action === "export" && <ExportSettingsContainer onExport={() => downloadSettings()} />}
      {action === "import" && (
        <div>
          <Row>
            <div className={styles.alertContainer}>
              <Alert
                isActive
                kind="info"
                icon="alert-circle"
                alignItems="center"
                isDismissable={false}
              >
                <span>Note: Configurations in the uploaded file are applied immediately and overwrites valid selected settings and ALL translations. Download a copy of the current environment configuration before uploading in case of error.</span>
              </Alert>
            </div>
          </Row>
          <div className="padding--top--s">
            <Row alignItems="center">
              <Row.Item>
                <TextInput
                  type="file"
                  onChange={async (e) => e?.target?.files ? loadSettings(e.target.files[0]) : clearData()}
                  name="import_setting_file"
                  id="import_setting_file"
                  accept={["text/json"]}
                  showClearButton
                />
              </Row.Item>
            </Row>
          </div>
          {Object.keys(importedSettings || {}).length > 0 ? (
            <>
              <div className={`padding--y--m ${styles.alertContainer}`}>
                <div className="padding--bottom--s">
                  <Alert
                    isActive={importingInstitutionName}
                    kind={importingInstitutionName === currInternalName ? "success" : "warn"}
                    icon="alert-triangle"
                    isDismissable={false}
                  >
                    <span>
                      <ReactMarkdown>{
                        importingInstitutionName === currInternalName
                          ? `You are uploading a configuration from the same environment **${importingInstitutionName}**.`
                          : `You are uploading settings from **${importingInstitutionName}** to **${currInternalName}**. Verify this is what you are expecting to do.`
                      }</ReactMarkdown>
                    </span>
                  </Alert>
                </div>
                <ReleaseVersionAlert />
              </div>
              <div className="padding--bottom--xl">
                <SelectableSettingsList currSettings={currSettings} newSettings={importedSettings} selected={settingsToOverwrite} setSelected={setSettingsToOverwrite} setSettingToCompare={setSettingToCompare} />
              </div>
              {

                settingToCompare && <SettingComparisonModal
                  newValue={importedSettings[settingToCompare]}
                  currSetting={currSettings[settingToCompare]}
                  isOpen={settingToCompare !== null}
                  setSettingToCompare={setSettingToCompare}
                />
              }
              <div className={styles.applySnackBar}>
                <Snackbar isActive={Object.keys(importedSettings || {}).length !== 0}>
                  <Snackbar.Text>{Object.keys(settingsToOverwrite || {}).length} of {Object.keys(importedSettings).length} selected to overwrite</Snackbar.Text>
                  <Snackbar.Divider />
                  <Snackbar.ButtonGroup>
                    <Button
                      type="button"
                      kind="plain"
                      onClick={() => {
                        const numSettings = settingsToOverwrite ? Object.keys(settingsToOverwrite).length : 0
                        if (numSettings === Object.keys(importedSettings).length) {
                          setSettingsToOverwrite({})
                          return
                        }
                        setSettingsToOverwrite(importedSettings)
                      }
                      }>
                      {settingsToOverwrite && Object.keys(settingsToOverwrite).length === Object.keys(importedSettings).length ? "Clear all" : "Select all"}
                    </Button>
                    <Button disabled={Object.keys(settingsToOverwrite || {}).length === 0} onClick={() => setConfirmModalOpen(true)}>
                      Apply
                    </Button>
                  </Snackbar.ButtonGroup>
                </Snackbar>
              </div>
              <SyncConfirmationModal
                currInstitutionName={currInternalName}
                importingInstitutionName={importingInstitutionName}
                isConfirmModalOpen={confirmModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
                toSync={settingsToOverwrite}
                onConfirm={async () => { await submit(); }}
              />
            </>
          ) : (Object.entries(settingsToOverwrite).length === 0 && importingInstitutionName && (<h3 className="padding--top">No settings to import</h3>))}
        </div>)}
    </div>
  )
}

export default SyncSettingsContainer;
