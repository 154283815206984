// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alertContainer--Q53Ji .nds-alert .nds-row {\n  align-items: center!important;\n}\n\n.applySnackBar--fnmBK {\n  position: fixed;\n  bottom: 0;\n  padding: 1rem;\n}\n\n.syncSettingsList--tb01V {\n  padding-bottom: var(--space-s);\n\n  .collapsible-card--content-card {\n    z-index: 0 !important;\n  }\n\n}\n", "",{"version":3,"sources":["webpack://./src/institution_settings/sync/styles/SyncSettingsContainer.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,SAAS;EACT,aAAa;AACf;;AAEA;EACE,8BAA8B;;EAE9B;IACE,qBAAqB;EACvB;;AAEF","sourcesContent":[".alertContainer :global(.nds-alert) :global(.nds-row) {\n  align-items: center!important;\n}\n\n.applySnackBar {\n  position: fixed;\n  bottom: 0;\n  padding: 1rem;\n}\n\n.syncSettingsList {\n  padding-bottom: var(--space-s);\n\n  :global(.collapsible-card--content-card) {\n    z-index: 0 !important;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertContainer": "alertContainer--Q53Ji",
	"applySnackBar": "applySnackBar--fnmBK",
	"syncSettingsList": "syncSettingsList--tb01V"
};
export default ___CSS_LOADER_EXPORT___;
