import React, { useMemo } from "react";
import { getTitleDisplay, hasReformatTitle } from "./utils";
import Setting from "./Setting";

// Settings that have a value preview hidden in the settings list
const HIDE_VALUE_SETTING_NAMES = ["OAO_ASK_ADDITIONAL_FIELDS"];

const SettingsList = ({ settingsList, getSettingPath, group, subgroup, searchTerm }) => {
  const memoizedSortedSettingsList = useMemo(() => {
    if (searchTerm) {
      return settingsList;
    }

    return [...settingsList].sort((settingA, settingB) => {
      // move deprecated settings to the bottom
      const isDeprecatedA = settingA.human_facing_name.includes("Deprecated");
      const isDeprecatedB = settingB.human_facing_name.includes("Deprecated");

      if (isDeprecatedA && !isDeprecatedB) {
        return 1;
      }
      if (!isDeprecatedA && isDeprecatedB) {
        return -1;
      }
      if (settingA.human_facing_name < settingB.human_facing_name) {
        return -1;
      }
      if (settingA.human_facing_name > settingB.human_facing_name) {
        return 1;
      }
      return 0;
    });
  }, [settingsList]);

  return (
    <div>
      <div className="segment nds-typography">
        <h4
          className="nds-sans"
          id={`${group}_${subgroup.replace(/ /g, "_")}`}
          style={{
            textTransform: hasReformatTitle(subgroup.toLowerCase()) ? "none" : "capitalize",
          }}
        >
          {settingsList.length !== 0 ? getTitleDisplay(subgroup.toLowerCase()) : null}
        </h4>
        <hr />
        <div className="settings">
          {memoizedSortedSettingsList.length !== 0
            ? memoizedSortedSettingsList.map((setting, index) => (
              <div key={`${setting.name}_${group}`}>
                {index > 0 ? <hr /> : null}
                <Setting
                  setting={setting}
                  editRoute={getSettingPath(setting)}
                  searchTerm={searchTerm}
                  hideSettingValue={HIDE_VALUE_SETTING_NAMES.includes(setting.name)}
                />
              </div>
            ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
